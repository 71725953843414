export const messages = {
  error: `Une erreur est survenue, merci de réessayer.`,
  'unauthorized': 'Vos identifiants sont incorrects.',
  'forbidden': 'Vous n\'êtes pas autorisé à vous connecter.',
  'user-not-found': `Cet utilisateur n'a pas été trouvé.`,
  'input-date-minor': "Vous devez avoir 18 ans ou plus pour pouvoir investir. Nous ne sommes malheureusement pas en mesure de donner suite à votre demande.",
  'email-not-found': `Cette adresse email n'a pas été trouvée.`,
  'credentials-invalid': `Votre email ou votre mot de passe est incorrect.`,
  'password-invalid': `Veuillez saisir un mot de passe valide.`,
  'password-different': `La confirmation de mot de passe doit être identique à celle utilisée ci-dessus.`,
  'code-invalid': `Le code n'est pas valide. Si le code ne fonctionne pas, renvoyez le ou contactez le service client.`,
  'token-invalid': `Token incorrect.`,
  'token-missing': `Token non trouvé`,
  'phone-invalid': `Ce numéro de téléphone semble invalide, merci de vérifier votre saisie.`,
  'posthog-unreachable': `Erreur de connexion au services de PostHog.`,
  'investor-invalid': `Veuillez vous assurer d'avoir conscience d'être traité comme un investisseur averti ainsi que de l'exactitude des informations transmises.`,

  'input-date-error': `Veuillez saisir une date valide.`,

  'input-required': `Veuillez répondre à cette question afin de continuer.`,
  'input-array-length': `Veuillez cocher au moins une de ces propositions pour continuer.`,
  'pappers-error': `Une erreur est survenue auprès de notre partenaire Pappers lors de votre recherche, merci de réessayer plus tard ou de contacter le service client.`,

  // API ERRORS
  'api-error': `Un problème technique est survenu. Veuillez réessayer plus tard.`,
  'invalid_parameters': `Le formulaire contient des données incorrectes.`,
  'not_in_range': `Cette valeur est incorrecte.`,
  'is_invalid': `Cette valeur est invalide.`,
  'is_invalid_or_already_taken': `Cet email est invalide ou déjà utilisé.`,
  'must_contain_at_least_one_digit': `Ce champ doit contenir au moins 1 chiffre`,
  'length_must_be_between_8_and_72_chars': `Cette valeur doit contenir entre 8 et 72 caractères`
}

export class Err {
  readonly ok = false
  readonly error: keyof typeof messages

  status?: number
  details?: {
    [key: string]: (keyof typeof messages)[]
  }
  type?: 'alert'

  constructor (
    error: keyof typeof messages,
    data?: {
      status?: number
      details?: {
        [key: string]: (keyof typeof messages)[]
      }
    }
  ) {
    this.error = error

    if (data?.status) this.status = data.status
    if (data?.details) this.details = data.details
  }

  public get message() {
    return messages[this.error] ? messages[this.error] : messages['error']
  }
}
