import { Ok, Err, Result, IUser } from '@/types'
import { fetchApi } from '@/logic/useApi'
import { useUserStore } from '@/stores'

export const authLogAPI = async (email: string, password: string) => {
  const response: Result<{ user: IUser; token: string; dashboard_url: null} | { user: null; token: null; dashboard_url: string }> = await fetchApi(
    'login',
    {
      email,
      password
    },
    {
      method: 'POST'
    }
  )

  if (response.ok && response.data.user && response.data.token) {
    localStorage.setItem('auth-token', response.data.token)
    return new Ok(response.data.user)
  }

  if (response.ok && response.data.dashboard_url) {
    const userStore = useUserStore()
    userStore.setDashboardUrl(response.data.dashboard_url)

    return new Ok(true)
  }

  if (!response.ok && response.error === 'unauthorized') {
    return new Err('credentials-invalid')
  }

  if (!response.ok && response.error === 'forbidden') {
    return new Err('forbidden')
  }

  return new Err('api-error')
}

export const authCheckAPI = async () => {
  const token = localStorage.getItem('auth-token')

  if (token) {
    const response: Result<{ data: IUser, dashboard_url: string | null }> = await fetchApi(
      'me',
      {},
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok && response.data.data) {
      const userStore = useUserStore()
      userStore.set(response.data.data)
      userStore.setDashboardUrl(response.data.dashboard_url)

      return new Ok(true)
    }

    return new Err('credentials-invalid')
  }

  return new Err('credentials-invalid')
}
